export default function Configs() {
    const desItems1= [
        { label: "桥梁编码", model: "bridgeCode", span: 1 },
        { label: "检测时间", model: "checkTime", span: 1 },
        { label: "总重", model: "grossWeight", span: 1 },
        { label: "车辆类型", model: "carClassName", span: 1 },
        { label: "车速", model: "speed", span: 1 },
        { label: "加速度", model: "acceleration", span: 1 },
        { label: "车辆颜色", model: "carColor", span: 1 },
        { label: "车牌", model: "carNum", span: 1 },
        { label: "车道号", model: "laneNum", span: 1 },
        { label: "车辆长度", model: "carLength", span: 1 },
        { label: "超限率", model: "overrunRate", span: 1 },
        { label: "违例码", model: "violationCode", span: 1 },
        { label: "超限标示", model: "overrunMark", span: 1 },
        { label: "当量轴次", model: "axisSecond", span: 1 },
      ]
      const desItems2= [
        { label: "左1轮重", model: "leftWheel1", span: 1 },
        { label: "左2轮重", model: "leftWheel2", span: 1 },
        { label: "左3轮重", model: "leftWheel3", span: 1 },
        { label: "左4轮重", model: "leftWheel4", span: 1 },
        { label: "左5轮重", model: "leftWheel5", span: 1 },
        { label: "左6轮重", model: "leftWheel6", span: 1 },
        { label: "左7轮重", model: "leftWheel7", span: 1 },
        { label: "左8轮重", model: "leftWheel8", span: 1 },
        { label: "右1轮重", model: "rightWheel1", span: 1 },
        { label: "右2轮重", model: "rightWheel2", span: 1 },
        { label: "右3轮重", model: "rightWheel3", span: 1 },
        { label: "右4轮重", model: "rightWheel4", span: 1 },
        { label: "右5轮重", model: "rightWheel5", span: 1 },
        { label: "右6轮重", model: "rightWheel6", span: 1 },
        { label: "右7轮重", model: "rightWheel7", span: 1 },
        { label: "右8轮重", model: "rightWheel8", span: 1 },
      ]
      const desItems3= [
        { label: "单轴数", model: "axleNum", span: 1 },
        { label: "轴组数", model: "axleGroupNum", span: 1 },
        { label: "轴1间距", model: "axleBase1", span: 1 },
        { label: "轴2间距", model: "axleBase2", span: 1 },
        { label: "轴3间距", model: "axleBase3", span: 1 },
        { label: "轴4间距", model: "axleBase4", span: 1 },
        { label: "轴5间距", model: "axleBase5", span: 1 },
        { label: "轴6间距", model: "axleBase6", span: 1 },
        { label: "轴7间距", model: "axleBase7", span: 1 },
      ]
    return {
        desItems1,
        desItems2,
        desItems3
    };
}
